<template>
	
	<div class="modal fade" :id="'font-' + label_id + '-modal'" tabindex="-1" :aria-labelledby="'font-' + label_id + '-modal-label'" aria-hidden="true">

		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" :id="'font-' + label_id + '-modal-label'">Choose {{ label }} font</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body p-0">
					
					<div class="w-100 d-flex flex-column">

						<div class="bg-white p-4 position-sticky">

							<p class="mb-3 small">Browse all available fonts at <a href="https://fonts.google.com/" target="_blank">Google Fonts</a></p>

							<form_control 
								name="font_keyword"
								type="text"
								placeholder="Start typing to filter fonts..."
								:include_delay="true"
								group_class="'mb-0'"
								v-model="font_keyword"
							/>
						</div>
						
						<div class="position-relative flex-grow-1 overflow-hidden">

							<div class="overflow-auto h-100 w-100">
							
								<div v-for="(font, font_key) in filtered_fonts" :key="'font-' + font_key">
									
									<div v-for="( variant, variant_key ) in font.variants" :key="'font-' + font_key + '-variant-' + variant_key">
										
										<div v-if="!variant.includes( 'italic' )">

											<label 
											:for="'design-' + font_key" 
											class="h-100 w-100 font-card position-prelative overflow-hidden position-relative border-top d-block"
											:class="( modelValue && modelValue['family'] == font.family && modelValue['weight'] == variant ? 'bg-success bg-opacity-25' : '' )"
											>

												<div class="card-body p-3 border-0 position-relative">

													<div class="d-flex h-100 align-items-center justify-content-between">
														<span>{{ font.family }} ({{ variant }})</span>

														<span v-if="modelValue && modelValue['family'] == font.family && modelValue['weight'] == variant" class="d-flex align-items-center text-success small">
															<span 
															class="material-icons-outlined text-success me-2"
															>check</span>

															Selected
														</span>
													</div>
													
												</div>

												<div class="card-body py-0 px-3 pb-3 position-relative" style="border-bottom-width: 1px !important;">
													<div v-if="!generated_fonts.includes( font.family+variant )" class="d-flex align-items-center" style="height: 65px;">
														<div class="spinner-border" role="status">
															<span class="visually-hidden">Loading...</span>
														</div>
													</div>
													<iframe v-on:load="generated_fonts.push( font.family+variant )" :src="get_font_preview( font, variant )" style="width: 100%; height: 65px;" class="rounded border px-4 bg-white" :class="!generated_fonts.includes( font.family+variant ) ? 'd-none' : ''" />
												</div>

												<button 
												type="button"
												class="stretched-link position-absolute w-100 h-100 start-0 top-0 bg-transparent border-0" 
												style="cursor: pointer;"
												v-on:click="update_model( font.family, variant )"
												></button>
											</label>
										</div>
									</div>
								</div>

							</div>

						</div>

					</div>
					
				</div>

				<div class="modal-footer">
					<button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal" aria-label="Close">Done</button>
				</div>

			</div>
		</div>

	</div>

</template>

<script>
import google_service from '@/services/google_service'
import form_control from '@/components/form_control'

export default {
	name: 'components.font_modal',

	props: {
		label: String,
		label_id: String,
		modelValue: [String, Number, Object, Boolean]
	},

	emits: [
		'update:modelValue'
	],

	components: {
		form_control,
	},

	data() {
		return {
			fonts: [],
			font_keyword: null,
			generated_fonts: [],
		}
	},

	computed: {

		filtered_fonts()
		{
			var fonts = this.fonts 

			if ( this.font_keyword ) {
				fonts = fonts.filter(( row ) => {
					var name = row.family.toLowerCase()
					return name.includes( this.font_keyword.toLowerCase() )
				})
			}

			fonts = fonts.slice( 0, 5 )

			return fonts
		},
	},

	mounted() 
	{
		var $ = window.$

		var vm = this

		$('#font-' + this.label_id + '-modal').on('shown.bs.modal', function () {
			vm.fetch_fonts()
		})
		
	},

	methods: {
		async fetch_fonts()
		{
			await google_service.fonts().then(( response ) => {
				this.fonts = response.data.items
			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading 	= false
			})
		},

		get_font_preview( font, variant )
		{
			var preview = process.env.VUE_APP_API_BASE_URL + 'api/workspace/' + this.$route.params.workspace_id + '/site/' + this.$route.params.site_id + '/font-preview'

			var query = 'font=' + font.family + '&weight=' + variant

			preview += '?' + query

			return preview
		},

		update_model( font, variant )
		{
			this.$emit('update:modelValue', {
				family: font,
				weight: variant
			})
		}
	}
}
</script>

<style>

</style>
