<template>
	
	<div :id="'planning-page-' + ( page ? page : 1 )" class="planning-page mb-4" :style="( team && team.button_text) ? 'background: ' + team.button_text + '; color: ' + team.button_bg + ';' : ''">

		<img v-if="team && team.logo" :src="team.logo" class="position-absolute top-0 start-50 translate-middle-x" style="width: 200%; height: auto; filter: brightness(0) contrast(100%); opacity: 0.025;">

		<div v-if="layout == 3" class="planning-page-footer d-flex align-items-center justify-content-between py-4" style="top: 0; bottom: auto;">
			<div>
				<h1 style="font-size: 30px;">{{ pages[step].name }}.</h1>					
			</div>
			<p v-if="include_description">{{ pages[step].description }}</p>
		</div>

		<div class="planning-page-content position-relative" :style="layout == 3 ? 'max-width: 100%;' : ''">			

			<div class="row justify-content-between">
				<div v-if="layout != 3" class="col-12 position-relative" :class="layout && layout == 2 ? '' : 'col-xxl-5'">

					<div v-if="!layout || layout != 2" class="h-100 border-end position-absolute top-0 p-0 d-none d-xxl-block" style="width: 1px; right: -2rem"></div>

					<h1 style="font-size: 40px;">{{ pages[step].name }}.</h1>

					<p v-if="include_description">{{ pages[step].description }}</p>

					<chat_message v-if="include_comment" content="Skip to the next step if you don't have this information available, you'll be able to add details and edit everything later." type="4" author="4" />
				</div>

				<div class="col-12  d-flex align-items-center" :class="layout && layout != 1 ? ( layout == 3 ? 'p-0' : '' ) : 'col-xxl-6'">

					<div class="w-100" v-if="step == 'brand_logos'">
						
						<div class="d-flex border rounded overflow-hidden">

							<div class="col border-end d-flex align-items-end">
								<div class="w-100">
									<div v-if="value.settings['dark-logo']" class="w-100 mb-2">
										<button class="btn btn-link text-danger p-3" v-on:click="[ value.settings['dark-logo'] = '' ]">
											Remove image
										</button>
										<div class="img-bg img-bg-lg position-relative">
											<img :src="value.settings['dark-logo']" height="50" style="max-width: 100%;" />
										</div>
									</div>
									<div v-else class="w-100 mb-2">
										<div class="img-bg img-bg-lg position-relative border-bottom"></div>
									</div>

									<form_control
										type="brand_file" 
										name="dark_logo"
										label="Dark Logo"
										placeholder="Upload Image"
										group_class="d-flex align-items-center justify-content-between p-3"
										field_class="form-control rounded-0 border-0 bg-transparent" 
										label_class="mb-0 w-50 d-block"
										v-model="value.settings['dark-logo']"
									/>
								</div>
							</div>

							<div class="col border-end d-flex align-items-end">
								<div class="w-100">
									<div v-if="value.settings['light-logo']" class="w-100 mb-2">
										<button class="btn btn-link text-danger p-3 pe-4" v-on:click="[ value.settings['light-logo'] = '' ]">
											Remove image
										</button>
										<div class="img-bg img-bg-lg position-relative">
											<div class="bg-dark w-100 h-100 position-absolute top-0 start-0 opacity-50"></div>
											<img :src="value.settings['light-logo']" height="50" style="max-width: 100%;" class="position-relative" />
										</div>
									</div>
									<div v-else class="w-100 mb-2">
										<div class="img-bg img-bg-lg position-relative">
											<div class="bg-dark w-100 h-100 position-absolute top-0 start-0 opacity-50"></div>
										</div>
									</div>

									<form_control
										type="brand_file" 
										name="light_logo"
										label="Light Logo"
										placeholder="Upload Image"
										group_class="d-flex align-items-center justify-content-between p-3"
										field_class="form-control rounded-0 border-0 bg-transparent" 
										label_class="mb-0 w-50 d-block"
										v-model="value.settings['light-logo']"
									/>
								</div>
							</div>

							<div class="col d-flex align-items-end">
								<div class="w-100">
									<div v-if="value.settings['favicon']" class="w-100 mb-2">
										<button class="btn btn-link text-danger p-3 pe-4" v-on:click="[ value.settings['favicon'] = '' ]">
											Remove image
										</button>
										<div class="img-bg img-bg-lg"><img :src="value.settings['favicon']" height="50" style="max-width: 100%;" /></div>
									</div>
									<div v-else class="w-100 mb-2">
										<div class="img-bg img-bg-lg"></div>
									</div>

									<form_control
										type="brand_file" 
										name="favicon"
										label="Favicon"
										placeholder="Upload Image"
										group_class="d-flex align-items-center justify-content-between p-3"
										field_class="form-control rounded-0 border-0 bg-transparent" 
										label_class="mb-0 w-50 d-block"
										v-model="value.settings['favicon']"
									/>
								</div>
							</div>

						</div>
					
					</div>

					<div class="w-100" v-if="step == 'colors'">
						<div class="row row-cols-2 row-cols-xxl-4">
													
							<div v-for="( color, color_key ) in colors" class="col mb-3" :key="'styles-color-' + color_key">
								<div class="w-100 mb-2 border">
									<div class="img-bg" :style="'background: ' + value.settings[color.key]"></div>
								</div>
								<form_control 
									type="brand_color" 
									:name="color.key"
									:label="color.name"
									group_class="align-items-center d-flex justify-content-between"
									field_class="form-control rounded-0 border-0 bg-transparent" 
									label_class="mb-0"
									v-model="value.settings[color.key]"
								/>
							</div>

						</div>
					</div>

					<div class="w-100 pb-4" v-if="step == 'fonts'">
						<div v-for="( font, font_key ) in fonts" :key="'detail-font-' + font_key" class="mb-1 border-bottom pb-2">

							<div class="py-2 pb-0">
								<form_control
									:label="font.name + ':'"
									type="font"
									placeholder="Select font"
									:name="'settings.' + font.key"
									:input_key="font.key"
									group_class="mb-0 d-flex align-items-center"
									label_class="mb-0"
									:error="error"
									v-model="value.settings[font.key]"
								/>
							</div>

							<div v-if="value.settings[font.key]" class="w-100 h-100 mb-1">

								<div class="d-flex align-items-center w-100 overflow-hidden">
										
									<div v-if="!generated_fonts.includes( value.settings[font.key]['family']+value.settings[font.key]['weight'] )" class="d-flex align-items-center" style="height: 65px;">
										<div class="spinner-border" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>
									
									<iframe v-on:load="generated_fonts.push( value.settings[font.key]['family']+value.settings[font.key]['weight'] )" :src="get_font_preview( value.settings[font.key]['family'], value.settings[font.key]['weight'] )" style="width: 100%; height: 65px;" :class="!generated_fonts.includes( value.settings[font.key]['family']+value.settings[font.key]['weight'] ) ? 'd-none' : ''" />

								</div>

							</div>

						</div>
					</div>

					<div class="w-100" v-else-if="step == 'details'">
						<form_control
							label="Domain Name"
							icon="dns"
							type="text"
							placeholder="eg: https://examplesite.com"
							name="settings.url"
							:required="true"
							:error="error"
							v-model="value.settings['url']"
							v-on:update:modelValue="update"
						/>

						<form_control
							label="Project Name"
							icon="verified"
							type="text"
							placeholder="eg: My Project"
							name="name"
							:required="true"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.name"
						/>

						<form_control
							label="Client Name"
							icon="badge"
							type="text"
							placeholder="eg: My Client"
							name="settings.name"
							:required="true"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['name']"
						/>

						<form_control
							label="Industry"
							icon="factory"
							type="select"
							:options="store_industries"
							name="settings.industry"
							:required="true"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['industry']"
						/>

						<form_control
							label="Purpose of Website"
							icon="language"
							type="select"
							name="settings.purpose"
							:options="[
								{ value: undefined, name: 'Select Purpose' },
								{ value: 'corporate', name: 'Corporate / Business' },
								{ value: 'support', name: 'Customer Support' },
								{ value: 'entertainment', name: 'Entertainment' },
								{ value: 'government', name: 'Government' },
								{ value: 'healthcare', name: 'Healthcare' },
								{ value: 'educational', name: 'Informational / Educational' },
								{ value: 'marketing', name: 'Marketing' },
								{ value: 'news', name: 'News and Media' },
								{ value: 'portfolio', name: 'Portfolio' }
							]"
							:required="true"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['purpose']"
						/>
					</div>

					<div class="w-100" v-if="step == 'contact'">
						<form_control
							label="Email"
							type="email"
							icon="email"
							placeholder="eg: support@mycompany.com"
							name="settings.email"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['email']"
						/>

						<form_control
							label="Phone"
							type="tel"
							icon="phone"
							placeholder="eg: 0123456789"
							name="settings.phone"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['phone']"
						/>

						<form_control
							label="Address"
							type="text"
							icon="place"
							placeholder="eg: AREOI, Huddersfield, HD5 0AN"
							name="settings.address"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['address']"
						/>
					</div>

					<div class="w-100" v-if="step == 'content'">
						<form_control
							label="Tone of voice"
							icon="record_voice_over"
							type="select"
							name="settings.tone_of_voice"
							:options="[
								{ value: undefined, name: 'Select tone of voice' },
								{ value: 'professional', name: 'Professional - Formal' },
								{ value: 'friendly', name: 'Friendly - Conversational' },
								{ value: 'inspirational', name: 'Inspirational - Motivational' },
								{ value: 'authoritative', name: 'Authoritative - Expert' },
								{ value: 'witty', name: 'Witty - Humorous' },
								{ value: 'persuasive', name: 'Persuasive - Sales-Oriented' },
								{ value: 'inquisitive', name: 'Inquisitive - Curious' },
								{ value: 'empathetic', name: 'Empathetic - Compassionate' },
								{ value: 'innovative', name: 'Innovative - Creative' },
								{ value: 'minimalistic', name: 'Minimalistic - Simplistic' },
							]"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['tone_of_voice']"
						/>

						<form_control
							label="Heading style"
							icon="format_bold"
							type="select"
							name="settings.heading_style"
							:options="[
								{ value: undefined, name: 'Select heading style' },
								{ value: 'title-case', name: 'Title case' },
								{ value: 'sentence-case', name: 'Sentence case' },
							]"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['heading_style']"
						/>

						<form_control
							label="Language usage"
							icon="subtitles"
							type="select"
							name="settings.language_usage"
							:options="[
								{ value: undefined, name: 'Select language usage' },
								{ value: 'passive', name: 'Passive' },
								{ value: 'active', name: 'Active' },
							]"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['language_usage']"
						/>
					</div>

					<div class="w-100" v-if="step == 'social'">
						<div v-for="(social, social_key ) in socials" :key="'detail-social-' + social_key">
							<form_control
								:label="social.name"
								type="text"
								:name="'settings.' + social.key"
								:error="error"
								:placeholder="social.placeholder"
								v-on:update:modelValue="update"
								v-model="value.settings[social.key]"
							/>
						</div>
					</div>

					<div class="w-100 h-100" v-if="step == 'history'">
						<form_control
							label="History"
							icon="history"
							type="textarea"
							:placeholder="'Enter details about ' + value.name + ' and their history.'"
							name="settings.about"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['about']"
							group_class="h-100 d-flex flex-column"
							field_class="flex-grow-1 form-control"
						/>
					</div>

					<div class="w-100 h-100" v-if="step == 'mission'">
						<form_control
							label="Mission"
							icon="emoji_events"
							type="textarea"
							:placeholder="'Enter ' + value.name + '\'s mission statement.'"
							name="settings.mission"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['mission']"
							group_class="h-100 d-flex flex-column"
							field_class="flex-grow-1 form-control"
						/>
					</div>

					<div class="w-100 h-100" v-if="step == 'values'">
						<form_control
							type="options"
							name="settings.values"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['values']"
						/>
					</div>

					<div class="w-100 h-100" v-if="step == 'audience'">
						<form_control
							icon="people"
							type="options"
							name="settings.audience"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['audience']"
							group_class="h-100 d-flex flex-column"
							field_class="flex-grow-1 form-control"
						/>
					</div>

					<div class="w-100 h-100" v-if="step == 'clients'">
						<form_control
							icon="people"
							type="options"
							name="settings.clients"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['clients']"
							group_class="h-100 d-flex flex-column"
							field_class="flex-grow-1 form-control"
						/>
					</div>

					<div class="w-100" v-if="step == 'team'">
						<form_control
							type="options"
							name="settings.team"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['team']"
						/>
					</div>

					<div class="w-100" v-if="step == 'services'">
						<form_control
							type="options"
							name="settings.services"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['services']"
						/>
					</div>

					<div class="w-100" v-if="step == 'usps'">
						<form_control
							type="options"
							name="settings.usps"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['usps']"
						/>
					</div>

					<div class="w-100" v-if="step == 'features'">
						<form_control
							type="options"
							name="settings.features"
							:required="false"
							:error="error"
							v-on:update:modelValue="update"
							v-model="value.settings['features']"
						/>
					</div>

					<div class="w-100" v-if="step == 'plugins'">
						<form_control
							type="plugins"
							name="settings.plugins"
							:required="false"
							:error="error"
							:include_delay="true"
							v-on:update:modelValue="update"
							v-model="value.settings['plugins']"
						/>
					</div>
				</div>
			</div>		

		</div>

		<div class="planning-page-footer d-flex align-items-center justify-content-between">
			<div>
				<img v-if="team && team.logo" :src="team.logo" height="40" class="d-block">
			</div>
			<p v-if="page && total" class="planning-page-numbers mb-0">Page {{ page }} of {{ total }}</p>
		</div>	
	</div>

	<font_modal v-if="step == 'fonts'" label="heading" label_id="heading" key="heading" v-model="value.settings['heading']" />

	<font_modal v-if="step == 'fonts'" label="heading 2" label_id="heading_2" key="heading_2" v-model="value.settings['heading_2']" />

	<font_modal v-if="step == 'fonts'" label="heading 3" label_id="heading_3" key="heading_3" v-model="value.settings['heading_3']" />

	<font_modal v-if="step == 'fonts'" label="heading 4" label_id="heading_4" key="heading_4" v-model="value.settings['heading_4']" />

	<font_modal v-if="step == 'fonts'" label="heading 5" label_id="heading_5" key="heading_5" v-model="value.settings['heading_5']" />

	<font_modal v-if="step == 'fonts'" label="heading 6" label_id="heading_6" key="heading_6" v-model="value.settings['heading_6']" />

	<font_modal v-if="step == 'fonts'" label="body" label_id="body" key="body" v-model="value.settings['body']" />

</template>

<script>
import { mapGetters } from 'vuex';
import chat_message from '@/components/chat_message'
import form_control from '@/components/form_control'
import font_modal from '@/components/font_modal'

export default {
	name: 'components.planning_page',

	props: {
		modelValue: [String, Number, Object, Boolean, Array],
		team: Object,
		step: String,
		include_comment: Boolean,
		include_description: Boolean,
		layout: Number,
		page: Number,
		total: Number
	},

	emits: [
		'update:modelValue',
	],

	components: {
		chat_message,
		form_control,
		font_modal
	},

	computed: {

		...mapGetters( 'site', ['store_industries'] ),

		value: {
			get() {
				return this.modelValue
			},
			set( value ) {
				console.log(value)
				this.$emit('update:modelValue', value)				
			}
		},
	},

	data() {
		return {
			error: {},
			generated_fonts: [],
			pages: {
				brand_logos: {
					name: 'Logos',
					description: '...'
				},
				colors: {
					name: 'Colours',
					description: '...'
				},
				fonts: {
					name: 'Fonts',
					description: '...'
				},
				details: {
					name: 'Project details',
					description: 'These project details are essential. They guide the design and development process, ensuring the site meets specific goals and industry standards. Clear project details help tailor the site to the client\'s needs and target audience.'
				},
				contact: {
					name: 'Contact details',
					description: 'Contact details are crucial on a website for building trust, facilitating support, and enabling enquiries. Clear contact info ensures visitors can easily reach the company, enhancing user experience.'
				},
				social: {
					name: 'Social profiles',
					description: 'Including social media profiles on a website expands online presence, engages a broader audience, and fosters community interaction. It enhances credibility, drives traffic, supports marketing, and strengthens customer relationships.'
				},
				history: {
					name: 'Client history',
					description: 'A company\'s history records its founding, development, and milestones. Highlighting this history on a website adds depth, authenticity, and builds credibility, showcasing its journey and achievements while providing context for its mission.'
				},
				mission: {
					name: 'Client mission',
					description: 'A company\'s mission defines its purpose. Knowing the mission ensures the website aligns with the brand identity, tailors content and design to company goals and the target audience, and communicates the company\'s unique value proposition.'
				},
				values: {
					name: 'Core values',
					description: 'Company values guide behavior and decision-making. Understanding these values ensures the website\'s content, design, and functionality reflect the company\'s ethics and culture, fostering trust and credibility. Highlighting values attracts like-minded customers and employees, differentiates the brand, and enhances user experience with a genuine, cohesive message.'
				},
				audience: {
					name: 'Target audience',
					description: 'Customer personas are fictional profiles representing target audience segments. They guide content, design, and functionality decisions to meet specific needs and preferences. Using personas ensures the website is user-centric, engaging, and effective in reaching its intended audience.'
				},
				clients: {
					name: 'Key customers',
					description: 'Key customers or clients are crucial for showcasing credibility and success. Highlighting them on a website builds trust, demonstrates reliability, and attracts new clients. Featuring key customers helps illustrate the company\'s experience and expertise in serving reputable clients.'
				},
				team: {
					name: 'Core team',
					description: 'A company\'s team is the group behind its success. Featuring the team on a website showcases their expertise, diversity, and passion, humanising the brand and building trust. It fosters transparency and credibility, demonstrates commitment to employees and customers, and supports the brand\'s narrative and enhances user engagement.'
				},
				services: {
					name: 'Service offering',
					description: 'Service offerings are the products or services a company provides. Clearly presenting them on a website informs visitors, helps customers understand their value, builds trust, and drives conversions. Highlighting offerings differentiates the company and aligns with its mission and values.'
				},
				usps: {
					name: 'Unique selling points',
					description: 'Unique selling points (USPs) set a company\'s products or services apart. Highlighting USPs on a website attracts customers by showcasing unique value and competitive advantages. This enhances user experience, builds trust, and drives conversions.'
				},
				features: {
					name: 'Custom functionality',
					description: 'Custom features enhance a website by providing tailored functionality. They address specific needs, improve user experience, and differentiate the site from competitors. Custom features ensure the site aligns perfectly with business goals and user expectations. Add any custom features that need to be included on the site.'
				},
				plugins: {
					name: 'Third party plugins',
					description: 'Third-party plugins enhance a website\'s functionality. Choosing the right plugins adds features, improves performance, and boosts user experience. They integrate seamlessly, provide additional capabilities, and help the site meet specific needs efficiently. Select all third party plugins you\'d like to include on the site, Pablo will install and activate them once you progress to the build stage.'
				},
				content: {
					name: 'Content considerations',
					description: 'Content options like tone of voice, heading style, and language usage are crucial for an effective website. These elements make the site engaging, accessible, and aligned with the company\'s goals and brand.'
				},
			},
			socials: [
				{
					name: 'Facebook',
					key: 'facebook',
					placeholder: 'https://facebook.com/profile'
				},
				{
					name: 'Instagram',
					key: 'instagram',
					placeholder: 'https://instagram.com/profile'
				},
				{
					name: 'LinkedIn',
					key: 'linkedin',
					placeholder: 'https://linkedin.com/profile'
				},
				{
					name: 'Pinterest',
					key: 'pinterest',
					placeholder: 'https://pinterest.com/profile'
				},
				{
					name: 'TikTok',
					key: 'tiktok',
					placeholder: 'https://tiktok.com/profile'
				},
				{
					name: 'Twitter / X',
					key: 'twitter',
					placeholder: 'https://x.com/profile'
				},
				{
					name: 'YouTube',
					key: 'youtube',
					placeholder: 'https://youtube.com/profile'
				},
			],
			colors: [
				{
					name: 'Primary',
					key: 'primary',
					description: ''
				},
				{
					name: 'Secondary',
					key: 'secondary',
					description: ''
				},
				{
					name: 'Dark',
					key: 'dark',
					description: ''
				},
				{
					name: 'Light',
					key: 'light',
					description: ''
				},
				{
					name: 'Success',
					key: 'success',
					description: ''
				},
				{
					name: 'Warning',
					key: 'warning',
					description: ''
				},
				{
					name: 'Danger',
					key: 'danger',
					description: ''
				},
				{
					name: 'Info',
					key: 'info',
					description: ''
				},
			],
			fonts: [
				{
					name: 'H1',
					key: 'heading'	
				},
				{
					name: 'H2',
					key: 'heading_2'	
				},
				{
					name: 'H3',
					key: 'heading_3'	
				},
				{
					name: 'H4',
					key: 'heading_4'	
				},
				{
					name: 'H5',
					key: 'heading_5'	
				},
				{
					name: 'H6',
					key: 'heading_6'	
				},
				{
					name: 'Body',
					key: 'body'
				}
			],
		}
	},

	mounted() 
	{
		
		if ( this.value.settings['audience'] && !Array.isArray( this.value.settings['audience'] ) ) {
			this.value.settings['audience'] = []
		}
	},

	methods: {
		update()
		{
			this.$emit('update:modelValue', this.value)
		},

		get_font_preview( family, variant )
		{
			var preview = process.env.VUE_APP_API_BASE_URL + 'api/workspace/' + this.$route.params.workspace_id + '/site/' + this.$route.params.site_id + '/font-preview'

			var query = 'font=' + family + '&weight=' + variant

			preview += '?' + query

			return preview
		},
	}
}
</script>

<style>

</style>
